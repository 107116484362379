<template>
  <div class="page-user flex flex-between content-container">
    <!--左侧导航-->
    <div class="user-left-container">
      <!--个人信息-->
      <el-card class="user-info-container flex flex-center flex-dr">
        <div>
          <img src="@/assets/imgs/user.png" alt="" class="avatar">
        </div>
        <div class="name">{{ userInfo.name }}</div>
        <div class="info">
          <div class="role">{{enums.userFrom[userInfo.from]}}用户</div>
          <div class="username">{{ userInfo.account }}</div>
        </div>
      </el-card>
      <!--导航菜单-->
      <el-card class="nav-container" :body-style="{padding:'10px 0px'}">
        <div :class="url==='/user/info'?'li active':'li'" @click="gotoUrl('/user/info')">我的信息</div>
        <div :class="url==='/user/course'?'li active':'li'" @click="gotoUrl('/user/course')">我的课程</div>
      </el-card>
    </div>
    <!--右侧内容-->
    <el-card class="user-right-container">
      <router-view/>
    </el-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
import enums from "@/enums/index"

export default {
  name: "UserIndex",
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  data() {
    return {
      enums: enums,
      window: window,
      route: this.$route,
      url: "/user/info",
      navShowIndex: 0,
    }
  },
  mounted() {
    this.url = "/user/info"
  },
  methods: {
    gotoUrl(url) {
      this.url = url
      this.$router.push(url)
    }
  }
}
</script>

<style scoped lang="less">
@import '../../style/app.less';

.page-user {
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: start;
}

.user-left-container {
  width: 200px;

  .user-info-container {
    margin-bottom: 20px;

    img.avatar {
      width: 150px;
      height: 150px;
    }

    div.name {
      font-size: 16px;
      font-weight: 400;
      margin-top: 20px;
      margin-bottom: 20px;
      color: #333;
      text-align: center;
    }

    div.info {
      font-size: 14px;
      color: #666;
      text-align: center;

      div.role {
        margin-bottom: 5px;
      }
    }
  }

  .nav-container {
    background-color: #fff;

    .li {
      padding: 10px 60px;
      margin-bottom: 5px;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      border-right: 3px solid #fff;

      &:hover, &.active {
        color: @main-color;
        font-weight: bold;
        border-color: @main-color;
      }
    }
  }
}

.user-right-container {
  width: 980px;
}
</style>
